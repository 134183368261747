import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeetingStages(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/meeting_stage/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchMeetingStage(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/meeting_stage/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addMeetingStage(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/meeting_stage', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    }

  },
}
